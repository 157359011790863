<template>
  <div class="absolute sm:right-14 right-0 top-14 bg-white w-fit pl-3 pr-12 py-2.5 shadow-md z-10 rounded N800 P200">
    <a href="javascript:void(0);" class="flex gap-2 mb-4" @click.prevent="viewEmployee()">
      <img src="../assets/profile.svg" class="self-center" alt="profile" />
      <p>My profile</p>
    </a>
    <a class="flex gap-2" href="javascript:void(0);" @click.prevent="userLogout()">
      <img src="../assets/logout.svg" alt="logout" />
      <p>Logout</p>
    </a>
  </div>
</template>
<script setup lang="ts">
import { useAccessStore } from "@/store/store_access";
import { encodeUrlPath } from "@/helpers/utils";
import { logout } from "@/services/auth";
import { useStore } from "@/store";

const store = useStore();
const Access = useAccessStore();
const env = process.env;

const viewEmployee = () => {
  let encryptedId = encodeUrlPath(store?.user?.user_id?.toString());
  let hostedURL = env.VUE_APP_EMPLOYEE_URL;
  location.href = `${hostedURL}view-employee/${encryptedId}/personal`;
};


const userLogout = () => {
  Access.$reset();
  logout().then(() => {
    window.location.href = `${env.VUE_APP_SSO}/logout?login-hint=${store?.loginHint}&account=${store?.user?.email}&redirect-url=${env.VUE_APP_BASE}/login`;
  });
};
</script>
